<template>
	<div>
		<div class="type" v-for="(item,index) in list" :key="index">
			<template v-if="index % 2 === 0">
				<div class="images">
					<img :src="item.image" />
				</div>
				<div class="caseDetail">
					<div class="numAndtitle">
						<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/one.png" class="one" v-if="index == 0" />
						<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/two.png" class="two" v-if="index == 1" />
						<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/three.png" class="three" v-if="index == 2" />
						<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/four.png" class="four" v-if="index == 3" />
						<span>{{item.name}}</span>
					</div>
					<div class="FunctionIntroduction" v-html="item.text"></div>
				</div>
			</template>
			<template v-else>
				<div class="caseDetail">
					<div class="numAndtitle">
						<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/one.png" class="one" v-if="index == 0" />
						<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/two.png" class="two" v-if="index == 1" />
						<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/three.png" class="three" v-if="index == 2" />
						<img src="https://official-junran.oss-cn-hangzhou.aliyuncs.com/static/pc/four.png" class="four" v-if="index == 3" />
						<span>{{item.name}}</span>
					</div>
					<div class="FunctionIntroduction" v-html="item.text"></div>
				</div>
				<div class="images">
					<img :src="item.image" />
				</div>
			</template>
		</div>
		<div class="more_btn" @click="CustomerService">
			联系客服，了解更多功能
			<i class="el-icon-right"></i>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['list'],
		data() {
			return {

			}
		},
		methods: {
			CustomerService() {
				window.open(
					'https://tb.53kf.com/code/client/de4de17c24e193c4da45c1cb13c22a328/1'
				)
			},
		}
	}
</script>

<style scoped>
	.type {
		width: 50rem;
		height: 13.5rem;
		margin: 1.2rem auto 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.images {
		width: 24.84rem;
		height: 13.9rem;
	}

	.images>img {
		width: 100%;
		height: 100%;
	}

	.caseDetail {
		width: 21.7rem;
	}

	.numAndtitle {
		width: 100%;
		position: relative;
		height: 1.50rem;
	}

	.one {
		width: 3.63rem;
		height: 2.04rem;
		position: absolute;
		left: 0;
		bottom: 0.7rem;
	}

	.two {
		width: 5.16rem;
		height: 2.16rem;
		position: absolute;
		bottom: 0.7rem;
		left: 0;
	}

	.three {
		width: 5.07rem;
		height: 2.13rem;
		position: absolute;
		bottom: 0.7rem;
		left: 0;
	}

	.four {
		width: 5.04rem;
		height: 2.13rem;
		position: absolute;
		bottom: 0.7rem;
		left: 0;
	}

	.numAndtitle>span {
		color: #333333;
		font-size: 1.32rem;
		position: absolute;
		bottom: 0;
		left: 0;
		font-weight: bold;
	}

	.FunctionIntroduction {
		color: #666666;
		font-size: 1.02rem;
		margin-top: 1.5rem;
	}

	.more_btn {
		width: 16.2rem;
		height: 2.16rem;
		border-radius: 0.24rem;
		border: 0.06rem solid #D8D8D8;
		text-align: center;
		line-height: 2.16rem;
		margin: 1.98rem auto 0;
		color: #333333;
		font-size: 0.72rem;
		cursor: pointer;
	}

	.more_btn:hover {
		border: 0.06rem solid #13BE88;
		background-color: #13BE88;
		color: #FFFFFF;
	}
</style>